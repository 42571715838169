<template>
    <div class="exam-root-1">
    <div class="exam-top">
      <img src="../../assets/arrow_circle_back.png" style="cursor: pointer;width: 74px;height: auto" @click="goBack">
      <div style="margin-left: 50px;text-align: left">
        <div style="color: white;font-size: 30px;font-weight: 600;font-family: Montserrat-SemiBold;">
          2021-2022 FBLA China<br>
          National Leadership Conference and Economics Challenge
        </div>
      </div>
    </div>
    <div class="exam-content">
        <div class="certContainer">
            <img v-if="item.real_rank > 30 " src="../../assets/cert_30.jpg" class="model"/>
            <img v-if="item.real_rank <= 30 && item.real_rank>20" src="../../assets/cert_30.jpg" class="model"/>
            <img v-if="item.real_rank <= 20 && item.real_rank>10" src="../../assets/cert_20.jpg" class="model"/>
            <img v-if="item.real_rank <= 10" src="../../assets/cert_10.jpg" class="model"/>
            <div class="event">{{item.application_event}}</div>
            <!-- <div class="award">National {{`${item.real_rank}${rank(item.real_rank)}`}} Place</div> -->
            <div class="award">{{item.award_show_lable}}</div>
            <div class="student">
                <span class="name">{{`${upfirstName(student.student_givenName_pinyin)} ${upfirstName(student.student_lastName_pinyin)}`}}</span>
            </div>
            <div class="school">{{account.account_nameEN}}</div>
        </div>
    </div>
  </div>

</template>

<script>
import {findMyTranscript} from '../../api/index'
import {getUserId,getProjectCode} from '../../utils/store'
import '../../assets/common/font.css'

export default {
    data(){
        return{
            item:{},
            student:{},
            account:{},
            score_id:this.$route.query.score_id
        }
    },
    mounted(){
       this.fetchData()
    },
    methods:{
        fetchData(){
            findMyTranscript(getUserId(),getProjectCode()).then(res=>{
                let score = res.data.data.score_info.find(x=>x._id.$id===this.score_id)
                this.item = score.award_info.award
                this.student = res.data.data.student_info
                this.account = res.data.data.account_info
            })
        },
        handleEventName(event){
            if (event == undefined){
                return ''
            }
            let event_array = event.split(" ")
            console.log(111,event,event_array)
            return event_array[0]
        },

        goBack() {
            this.$router.go(-1)
        },
        upfirstName(name){
            let transferName = name.toLowerCase();
            return transferName.charAt(0).toUpperCase() + transferName.slice(1)
        },
        rank(number){
            if(number===1){
                return 'st';
            }else if(number===2){
                return 'nd';
            }else if(number===3){
                return 'rd';
            }else{
                return 'th'
            }
        },
        printPersonal(){
            let url = this.$router.resolve({
                path:'/blank'
            })
            let newWindow=window.open(url.href,"_blank");//打印窗口要换成页面的url
            let obj = document.getElementById('cerP')

            let docStr = obj.innerHTML;
            
            newWindow.document.write(docStr);
            
            newWindow.document.close();
            
            newWindow.print();
            
            newWindow.close();
            
        }
    }
}
</script>

<style lang="less">
@font-face {
    font-family: 'aleo-bold';
    src: url('../../assets/fonts/aleo-bold.ttf');
}
@font-face {
    font-family: 'Aleo-Bold-2';
    src: url('../../assets/fonts/Aleo-Bold-2.otf');
}
@font-face {
    font-family: 'Aleo-Italic-4';
    src: url('../../assets/fonts/Aleo-Italic-4.otf');
}
@font-face {
    font-family: 'Aleo-Light-5';
    src: url('../../assets/fonts/Aleo-Light-5.otf');
}
@font-face {
    font-family: 'Aleo-Regular-7';
    src: url('../../assets/fonts/Aleo-Regular-7.otf');
}
.el-dialog{
    width:320mm;
}
.exam-content {
  margin-top: -1px;
  width: 100%;
  background-image: url("../../assets/exam-content-bg.png");
  height: 82vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  padding: 30px 9vw;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.awardTitle{
    font-weight: bold;
    text-align: center;
    color: #0F429E;
    font-size: 60px;
    margin-top: 30px;
    font-family: Montserrat-SemiBold;
}
.awardTitle1{
    font-weight: bold;
    text-align: left;
    color: #0036A0;
    font-size: 30px;
    margin-top: 60px;
    margin-left: 80px;
    font-family: Montserrat-SemiBold;
}
.awardLine{
    background-color: #00359F;
    margin-left: 80px;
    margin-right: 80px;
    height: 1px;
    margin-top: 10px;
    font-family: Montserrat-SemiBold;
   
}
.scoreLab1{
  margin-top: 60px;   
  margin-right: 10px;
  height: 40px;
//   background-color: rebeccapurple;
  text-align: right;
  font-size: 30px; 
  color: #FD5900;
  float:right;
  font-family: Montserrat-SemiBold;

}

.scoreLab2{
  margin-top: 65px;   
  margin-right: 80px;  
  height: 40px;
//   background-color: rebeccapurple;
  text-align: right;
  color: #0F429E;
  font-size: 25px;
  float:right;
  font-family: Montserrat-SemiBold;
}
.awardSubTitle{
    text-align: left;
    color: #2D50B0;
    font-size: 15px;
    margin-top: 20px;
    margin-left: 80px;
    font-family: Montserrat-SemiBold;
}
.returnStyle{
    position: absolute;
    // display: flex;
    // margin-bottom: 10px;
    // align-items: center;
    bottom: 40px;
    margin-left: 45%;
    font-weight: 600;
    width: 140px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    // align-content: center;
    // align-items: center;
    border-radius: 15px;
    background-color: #2D50B0;
    color: white;
    font-family: Montserrat-SemiBold;
}
.box{
    display: flex;
    justify-content: left;
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 20px;
    // height: 250px;
    // background-color: #FD5900;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: left;
    align-content: left;
    text-align: left;
    float: left;
}
.child{
    // width: 50px;
    // background-color: aqua;
    height: 30px;
    padding-left: 10px;
}
.infoStyle{
    display: flex;
    border: 1px solid #2D50B0;
    border-radius: 16px;
    color: #2D50B0;
    margin-left: 20px;
    margin-top: -25px;
    font-family: Montserrat-SemiBold;
}
.infoImg{
    width: 14px;
    height: 14px;
}
.exam-top {
  background-image: url("../../assets/topBanner.png");
  height: 18vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 9vw;
}


.exam-root-1 {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0 auto;

}

.certContainer{
    position: relative;
    color: rgb(31,79,189);
    .model{
        width: 297mm;
        height: 208.7mm;
    }
    .event{
        position: absolute;
        font-size: 32px;
        top: 290px;
        left: 86px;
        font-weight: 300;
    }
    .award{
        position: absolute;
        font-size: 38px;
        top: 350px;
        left: 86px;
        font-weight: 600;
    }
    .student{
        position: absolute;
        top: 420px;
        left: 86px;
        font-size: 26px;
        font-style: italic;
        font-weight: 300;
        .name{
            margin-right: 40px;
        }
    }
    .school{
        position: absolute;
        top: 460px;
        left: 86px;
        font-size: 20px;
        font-weight: 300;
    }
}

</style>